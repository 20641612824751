import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';
import $ from 'jquery';

function HeroSection() {
    const scrollToEmailForm = () => {
        $('html, body').animate({
            scrollTop: $('#email-section').offset().top
        }, 1500);
    };

    const scrollToCards = () => {
        $('html, body').animate({
            scrollTop: $('#cards-div').offset().top
        }, 1000);
    };

    return (
        <div id='top' className='hero-container'>
          
            <img 
        className="hero-image" 
        src={`${process.env.PUBLIC_URL}/images/wo.jpg`} 
        alt="Hero Background" 
    />

            <div className='hero-text'>Take a trip</div> {/* Shtuar teksti */}

            <div className='hero-btns'>
                <Button className='btns' buttonStyle='btn--outline' buttonSize='btn--large' onClick={scrollToEmailForm}>
                    EMAIL
                </Button>
                <Button className='btns' buttonStyle='btn--primary' buttonSize='btn--large' onClick={scrollToCards}>
                    View
                </Button>
            </div>
        </div>
    );
}

export default HeroSection;
