import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <div className='footer-container'>
            <section className='footer-subscription'>
                <p className='footer-subscription-heading'>
                    Join the Adventure newsletter to receive our best vacation deals
                </p>
                <p className='footer-subscription-text'>
                    You can unsubscribe at any time.
                </p>
            </section>
            <div className='footer-content'>
                {/* Logo Section */}
                <div className='footer-logo'>
                    <img src={`${process.env.PUBLIC_URL}/images/ats.png`} alt="Logo" className="travel-logo-img" />
                </div>
                <div className='footer-links'>
                    <div className='footer-link-wrapper'>
                        <div className='footer-link-items'>
                            <h2>About Us</h2>
                            <a href='/get-started'>Get started</a>
                            <a href='/testimonials'>Testimonials</a>
                        </div>
                    </div>
                    <div className='footer-link-wrapper'>
                        <div className='footer-link-items'>
                            <h2>Services</h2>
                            <a href='malito'>info.wanderlust.albania@gmail.com</a>
                            <a href='malito'>sadjanmaraska@yahoo.com</a>
                        </div>
                    </div>
                    <div className='footer-link-wrapper'>
                     
                    </div>
                    <div className='footer-link-wrapper'>
                        <div className='footer-link-items'>
                            <h2>Contact Us</h2>
                            <a href='https://www.google.com/maps/place/Wanderlust+Albania/@42.0612606,19.5054416,15z/data=!4m2!3m1!1s0x0:0x50e69f81522de40b?sa=X&ved=1t:2428&ictx=111'> Bulevardi Zogu I, Shkodër 4001</a>
                            <a href='tel:+355684170770'>+355 684 170 770</a>
                            <a href='tel:+4917620544736'>+49 176 205 447 36</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-copyright'>
                <p>
                    &copy; {new Date().getFullYear()} <a href='https://www.absolutsystem.com' target='_blank' rel='noopener noreferrer'>Absolut Technology System</a>. All rights reserved.
                </p>
            </div>
        </div>
    );
}

export default Footer;
