// src/App.js

import React from 'react';
import './App.css';
import './components/locales/i18n';  // Import i18n initialization
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/pages/Home';
import AboutUS from './components/pages/AboutUS';
import Travels from './components/pages/Travels';
import TopNavbar from './components/TopNavbar';
import Hotels from './components/pages/Hotels';
import Car from './components/pages/Car';
import ClinicPage from './components/pages/ClinicPage';
import License from './components/pages/License';
import Photo from './components/pages/Photo';
import Slider from './components/Slider';
// import LanguageSwitcher from './components/LanguageSwitcher';

function App() {
  return (
    <Router>
      <TopNavbar />
      <Slider />
      {/* <LanguageSwitcher />  */}
      <div className="main-content">
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/Travels' element={<Travels />} />
          <Route path='/Hotels' element={<Hotels />} />
          <Route path='/Car' element={<Car />} />
          <Route path='/esthetic' element={<ClinicPage />} />
          <Route path='/licensed-guides' element={<License />} />
          <Route path='/places-to-visit' element={<Photo />} />
          <Route path='/about' element={<AboutUS />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
